import React from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import TooltipOverlay from '../TooltipOverlay';
import { X, XCircle } from 'react-bootstrap-icons';
import { useMediaQuery } from 'react-responsive';

const Record = ({ callMark, columns = 3, data, getEntryClass, index }) => {
  const isXS = useMediaQuery({
    query: '(max-width: 767px)',
  });

  const recordClass = getEntryClass(data, index);
  const colIndex = index + 1 - Math.floor((index + 1) / columns) * columns;
  const indexRow = Math.ceil((index + 1) / columns);
  const isFirstRow = indexRow === 1;
  const borderStart =
    !isXS && (!(colIndex % 2) || !(colIndex % 3)) ? 'border-start-0' : '';

  const borderBottom =
    (!isXS && !isFirstRow) || (isXS && !!index) ? 'border-top-0' : '';

  return (
    <Col
      xs={12}
      md={4}
      className={`border cursorPointer ${borderBottom} ${borderStart} p-0 text-white`}
      onClick={() => callMark(false, data)}
    >
      <Container fluid className="h-100 px-0">
        <Row className="g-0 h-100">
          <Col xs={2} sm={1}>
            <TooltipOverlay text="Flag as suspicious">
              <Button variant="dark" className="h-100 text-danger w-100">
                {recordClass === 'suspicious' ? <XCircle /> : <X />}
              </Button>
            </TooltipOverlay>
          </Col>
          <Col xs={10} sm={11} className={`ps-0 ${recordClass}`}>
            <Container fluid>
              <Row className="fw-bold g-0 p-2">
                <Col xs={8}>
                  {data.contributor_name}
                  <br />
                  <span className="text-light2">
                    {data.contributor_occupation}
                  </span>
                </Col>
                <Col xs={4} className="text-end">
                  ${data.contribution_receipt_amount.toLocaleString()}
                  <br />
                  <span className="text-light2">
                    {data.contribution_receipt_date}
                  </span>
                </Col>
              </Row>

              <Row className="fs-smaller p-2 pt-0 text-light2">
                <Col xs={6}>
                  {data.contributor_street_1} {data.contributor_street_2}
                  {(!!data.contributor_street_1 ||
                    !!data.contributor_street_2) && <br />}
                  {data.contributor_city}, {data.contributor_state}{' '}
                  {data.contributor_zip}
                </Col>
                <Col xs={6} className="text-end">
                  {data.committee.name}
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
    </Col>
  );
};

export default Record;
