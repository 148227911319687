import React, { useCallback } from 'react';
import { useMemo, useState } from 'react';
import {
  Button,
  ButtonGroup,
  Col,
  FloatingLabel,
  Form,
  FormGroup,
  FormLabel,
  InputGroup,
  Row,
} from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';

export const useSubmitModal = () => {
  const [email, setEmail] = useState(null);
  const [grounds, setGrounds] = useState(null);
  const [incorrectFields, setIncorrectFields] = useState([]);
  const [name, setName] = useState(null);
  const [notes, setNotes] = useState(null);
  const [onSubmit, setOnSubmit] = useState(null);
  const [phone, setPhone] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const handleGroundsChange = e => {
    const value = e.target.value;
    setGrounds(!!value && value !== '' ? value : null);
  };

  const handleOK = useCallback(() => {
    setShowModal(false);
    onSubmit({
      email,
      grounds,
      incorrectFields: incorrectFields.join(','),
      notes,
      name,
      phone,
    });
  }, [email, grounds, incorrectFields, name, notes, onSubmit, phone]);

  const handleIncorrectField = useCallback(
    e => {
      const index = incorrectFields.indexOf(e.target.value);
      if (index >= 0) {
        const newIncorrectFields = [...incorrectFields];
        newIncorrectFields.splice(index, 1);
        setIncorrectFields(newIncorrectFields);
      } else {
        if (e.target.value === 'none') setIncorrectFields([e.target.value]);
        else setIncorrectFields([...incorrectFields, e.target.value]);
      }
    },
    [incorrectFields],
  );

  const SubmitModal = useMemo(
    () => (
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Submit Suspicious Entries</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-3">
            By flagging a record as "suspicious, I certify that I have personal
            knowledge forming a good faith belief that the transaction is
            suspicious. I understand that these reports may be forwarded to law
            enforcement, and that making false claims to law enforcement may
            have legal consequences.
          </div>

          <FloatingLabel label="Who are you flagging for? (Required)">
            <Form.Select
              className="mb-3"
              value={grounds ?? ''}
              onChange={handleGroundsChange}
            >
              <option value="">-- Select one</option>
              <option value="self">
                I am flagging this donation on behalf of myself
              </option>
              <option value="other">
                I am flagging this donation as suspicious on behalf of someone
                else
              </option>
            </Form.Select>
          </FloatingLabel>

          <FormGroup className="mb-3 px-2">
            <FormLabel className="text-secondary">
              <small>Basis for report (Required)</small>
            </FormLabel>
            <Row>
              <Col xs={6}>
                <Form.Check
                  type="checkbox"
                  value="none"
                  label="All fields look correct"
                  checked={incorrectFields.includes('none')}
                  onChange={handleIncorrectField}
                />
              </Col>
              <Col xs={6}>
                <Form.Check
                  type="checkbox"
                  value="recipient"
                  disabled={incorrectFields.includes('none')}
                  label="Recipient is incorrect"
                  checked={incorrectFields.includes('recipient')}
                  onChange={handleIncorrectField}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={6}>
                <Form.Check
                  type="checkbox"
                  value="address"
                  disabled={incorrectFields.includes('none')}
                  label="Address is incorrect"
                  checked={incorrectFields.includes('address')}
                  onChange={handleIncorrectField}
                />
              </Col>
              <Col xs={6}>
                <Form.Check
                  type="checkbox"
                  value="occupation"
                  disabled={incorrectFields.includes('none')}
                  label="Occupation is incorrect"
                  checked={incorrectFields.includes('occupation')}
                  onChange={handleIncorrectField}
                />
              </Col>
              <Col xs={6}>
                <Form.Check
                  type="checkbox"
                  value="date"
                  disabled={incorrectFields.includes('none')}
                  label="Date is incorrect"
                  checked={incorrectFields.includes('date')}
                  onChange={handleIncorrectField}
                />
              </Col>
              <Col xs={6}>
                <Form.Check
                  type="checkbox"
                  value="amount"
                  disabled={incorrectFields.includes('none')}
                  label="Amount is incorrect"
                  checked={incorrectFields.includes('amount')}
                  onChange={handleIncorrectField}
                />
              </Col>
            </Row>
          </FormGroup>

          <FloatingLabel label="Additional Notes (Optional)">
            <Form.Control
              className="mb-3"
              type="text"
              value={notes ?? ''}
              onChange={e => setNotes(e.target.value)}
            />
          </FloatingLabel>

          <FloatingLabel
            label={`${
              grounds !== 'other' ? 'My Name' : "Other's Name"
            } (Required)`}
          >
            <Form.Control
              className="mb-3"
              type="text"
              value={name ?? ''}
              onChange={e => setName(e.target.value)}
            />
          </FloatingLabel>

          <InputGroup>
            <FloatingLabel label="Email (Optional)">
              <Form.Control
                type="email"
                value={email ?? ''}
                onChange={e => setEmail(e.target.value)}
              />
            </FloatingLabel>
            <FloatingLabel label="Phone  (Optional)">
              <Form.Control
                type="number"
                value={phone ?? ''}
                onChange={e => setPhone(e.target.value)}
              />
            </FloatingLabel>
          </InputGroup>
        </Modal.Body>
        <Modal.Footer>
          <ButtonGroup>
            <Button variant="light" onClick={() => setShowModal(false)}>
              Cancel
            </Button>
            <Button
              disabled={!grounds || !name || !incorrectFields?.[0]}
              onClick={handleOK}
            >
              Submit
            </Button>
          </ButtonGroup>
        </Modal.Footer>
      </Modal>
    ),
    [
      email,
      grounds,
      handleIncorrectField,
      handleOK,
      incorrectFields,
      name,
      notes,
      phone,
      showModal,
    ],
  );

  const submit = settings => {
    if (!!settings.email) setEmail(settings.email);
    if (!!settings.phone) setPhone(settings.phone);
    setOnSubmit(() => settings.onSubmit);
    setShowModal(true);
  };

  return { submit, SubmitModal };
};
