import React, { useState } from 'react';
import Alert from 'react-bootstrap/esm/Alert';
import Button from 'react-bootstrap/esm/Button';
import FloatingLabel from 'react-bootstrap/esm/FloatingLabel';
import Form from 'react-bootstrap/Form';
import StateDropdown from '../StateDropdown';
import { Col, Row } from 'react-bootstrap';

const Searchbar = ({
  city,
  loading,
  name,
  search,
  setCity,
  setName,
  setPriorIndex,
  setState,
  setZip,
  setYear,
  state,
  year,
  zip,
}) => {
  //const [showAdvanced, setShowAdvanced] = useState(false);
  const [error, setError] = useState(null);

  const onKeyUp = e => {
    if (e.key.toUpperCase() === 'ENTER') onSearch();
  };

  const onSearch = () => {
    if (!loading) {
      if (!name) setError('Please, enter a name');
      if (!state) setError('Please, select a state');
      else {
        setError(null);
        setPriorIndex([]);
        search();
      }
    }
  };

  return (
    <>
      <Row>
        <Col md={9} className="mb-3">
          <FloatingLabel label="Name">
            <Form.Control
              value={name ?? ''}
              onChange={e => setName(e.target.value)}
              onKeyUp={onKeyUp}
            />
          </FloatingLabel>
        </Col>

        <Col md={2} className="mb-3">
          <StateDropdown
            label="State"
            value={state}
            onChange={e => setState(e.target.value)}
          />
        </Col>

        <Col md={1} className="mb-3">
          {/*<Button variant="light" onClick={() => setShowAdvanced(!showAdvanced)}>
          Advanced
        </Button>*/}
          <Button
            disabled={!name || !state || !!loading}
            className="h-100 w-100"
            onClick={onSearch}
          >
            Search
          </Button>
        </Col>
      </Row>

      {true && (
        <Row>
          <Col md={2} className="mb-3 mb-md-0">
            <FloatingLabel label="City (Optional)">
              <Form.Control
                value={city ?? ''}
                onChange={e => setCity(e.target.value)}
              />
            </FloatingLabel>
          </Col>
          <Col md={2} className="mb-3 mb-md-0">
            <FloatingLabel label="Zip  (Optional)">
              <Form.Control
                value={zip ?? ''}
                onChange={e => setZip(e.target.value)}
              />
            </FloatingLabel>
          </Col>
          <Col md={2}>
            <FloatingLabel label="Year  (Optional)">
              <Form.Select
                value={year ?? ''}
                onChange={e => setYear(e.target.value)}
              >
                <option value={''}>-- Select a year</option>

                {'0'
                  .repeat(100)
                  .split('')
                  .map((_, index) => {
                    const year = new Date().getFullYear() - index;
                    return <option value={year}>{year}</option>;
                  })}
              </Form.Select>
            </FloatingLabel>
          </Col>
        </Row>
      )}

      {!!error && (
        <Alert variant="danger" className="mt-3 w-100">
          {error}
        </Alert>
      )}
    </>
  );
};

export default Searchbar;
