import React from 'react';
import Modal from 'react-bootstrap/Modal';
import {
  EmailShareButton,
  EmailIcon,
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  XIcon,
} from 'react-share';
import TooltipOverlay from '../TooltipOverlay';

const ShareModal = ({ onHide, show }) => {
  const url = 'https://CheckMyDonation.org';

  return (
    <Modal show={show} onHide={() => onHide()}>
      <Modal.Header closeButton>
        <Modal.Title>Share</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="mb-3">
          Protect your friends. Help others check donations reported in their
          names. Share CheckMyDonation.org.
        </div>

        <div className="text-center">
          <TooltipOverlay text="Email">
            <EmailShareButton
              url={url}
              className="me-2"
              subject="Checky My Donation"
              body={`See if you're a victim of the new "smurfing" donor fraud scheme?`}
            >
              <EmailIcon size={32} round />
            </EmailShareButton>
          </TooltipOverlay>
          <TooltipOverlay text="X">
            <TwitterShareButton
              url={url}
              className="me-2"
              title={`See if you're a victim of the new "smurfing" donor fraud scheme?`}
            >
              <XIcon size={32} round />
            </TwitterShareButton>
          </TooltipOverlay>
          <TooltipOverlay text="Facebook">
            <FacebookShareButton
              url={url}
              className="me-2"
              hashtag={`See if you're a victim of the new "smurfing" donor fraud scheme?`}
            >
              <FacebookIcon size={32} round />
            </FacebookShareButton>
          </TooltipOverlay>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ShareModal;
