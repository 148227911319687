import FingerprintJS from '@fingerprintjs/fingerprintjs';
let ip = null;
let name = null;

const updateAnalytics = async (eventName, eventParams) => {
  const clientId = '687347967.1724870709';
  const measurementId = 'G-VZSYBTFX4S';
  const secret = 'FVyy2vdyQe6q5OKOioWbyQ';
  const fingerprint = await FingerprintJS.load();
  const { visitorId } = await fingerprint.get();

  if (!ip)
    ip = (await (await fetch('https://api.ipify.org?format=json')).json()).ip;

  fetch(
    `https://www.google-analytics.com/mp/collect?measurement_id=${measurementId}&api_secret=${secret}`,
    {
      method: 'POST',
      body: JSON.stringify({
        client_id: clientId,
        events: [
          {
            name: eventName,
            params: {
              ...eventParams,
              ...(!!name ? { name } : {}),
              ...(!!ip ? { ip } : {}),
              ...(!!visitorId ? { visitorId } : {}),
            },
          },
        ],
      }),
    },
  );
};

const updateName = uName => (name = uName);

export { updateAnalytics, updateName };
