import FingerprintJS from '@fingerprintjs/fingerprintjs';
import Searchbar from '../Searchbar';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { useCallback, useEffect, useState } from 'react';
import Results from '../Results';
import { Alert, Button, Placeholder } from 'react-bootstrap';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Pagination from '../Pagination';
import { useSubmitModal } from '../SubmitModal';
import ShareModal from '../ShareModal';
import { X } from 'react-bootstrap-icons';
import { updateAnalytics, updateName } from '../../utilities';

function Home() {
  const [city, setCity] = useState(null);
  const [data, setData] = useState(null);
  const [draft, setDraft] = useState([]);
  const [email, setEmail] = useState(null);
  const [initialSearchStage, setInitialSearchStage] = useState(0);
  const [keyDisabled, setKeyDisabled] = useState(false);
  const [loading, setLoading] = useState(null);
  const [marking, setMarkings] = useState(null);
  const [name, setName] = useState(null);
  const [order, setOrder] = useState('-');
  const [phone, setPhone] = useState(null);
  const [priorIndex, setPriorIndex] = useState([]);
  const [searchParams] = useState(new URLSearchParams(window.location.search));
  const [searchParamsProcessed, setSearchParamsProcessed] = useState(false);
  const [overLimit, setOverLimit] = useState(false);
  const [sort, setSort] = useState('contribution_receipt_date');
  const [showShareModal, setShowShareModal] = useState(false);
  const [state, setState] = useState(null);
  const { submit, SubmitModal } = useSubmitModal();
  const [visitorId, setVisitorId] = useState(null);
  const [year, setYear] = useState(null);
  const [zip, setZip] = useState(null);

  const convertToParam = data =>
    new URLSearchParams(
      Object.fromEntries(Object.entries(data).filter(entry => !!entry?.[1])),
    ).toString();

  const handleSubmitClick = () => {
    submit({ email, onSubmit, phone });
    updateAnalytics('submitClicked', { name });
  };

  const onMark = data => {
    const draftSubIDs = (draft ?? []).map(row => row.subId);
    const included = draftSubIDs.includes(data.subId);
    let newDraft = included
      ? draft.filter(row => row.subId !== data.subId)
      : [...draft, data];

    updateAnalytics(included ? 'flagRemoved' : 'flagged', {
      name,
      subId: data.subId,
    });

    setDraft(newDraft);

    if (newDraft.length === 1)
      toast.info(
        'After you are done flagging suspicious transactions, click "Submit" below to submit your flags.',
      );
  };

  const onSubmit = async ({
    email,
    grounds,
    incorrectFields,
    name,
    notes,
    phone,
  }) => {
    setShowShareModal(true);

    updateAnalytics('submitted', { name });

    await fetch(`/markwrite`, {
      method: 'POST',
      body: JSON.stringify({
        mark: draft,
        meta: {
          email,
          grounds,
          incorrectFields,
          name,
          notes,
          phone,
          visitorId,
        },
      }),
    }).then(response => {
      setMarkings([...(marking ?? []), ...draft]);
      if (response.status === 200) {
        setMarkings([...(marking ?? []), ...(draft ?? [])]);
        setDraft([]);
        toast.success('Entry marked succesfully!');
      } else toast.error('Issue marking entry');
    });
  };

  const search = useCallback(
    (uSort = null, uOrder = null, uPriorIndex = null) => {
      if (loading) return;

      const finalSort = uSort !== null ? uSort : sort;
      const finalOrder = uOrder !== null ? uOrder : order;
      const finalPriorIndex = uPriorIndex !== null ? uPriorIndex : priorIndex;

      if (uSort !== null) setSort(uSort);
      if (uOrder !== null) setOrder(uOrder);
      if (uPriorIndex !== null) setPriorIndex(uPriorIndex);

      setLoading(!uSort && !uOrder);

      updateName(name);

      updateAnalytics('search', { name });

      fetch(
        `/fetch?sort_hide_null=false&sort_nulls_last=false&per_page=100&is_individual=true${`&${convertToParam(
          {
            ...finalPriorIndex[finalPriorIndex.length - 1],
            contributor_city: city,
            contributor_name: name,
            contributor_state: state,
            contributor_zip: zip,
            max_date: !!year ? `${year}-12-31` : null,
            min_date: !!year ? `${year}-01-01` : null,
            sort: `${finalOrder}${finalSort}`,
          },
        )}`}`,
      ).then(async response => {
        const data = await response.json();
        setLoading(false);
        setData(data);

        if (data?.error?.code === 'OVER_RATE_LIMIT') {
          setMarkings([]);
          setOverLimit(true);
          updateAnalytics('overRateLimit', {
            key: data?.apiKey,
            url: window.location.href,
          });
        } else if (data?.error?.code === 'API_KEY_DISABLED') {
          setMarkings([]);
          setKeyDisabled(true);
          updateAnalytics('apiKeyDisabled', {
            key: data?.apiKey,
            url: window.location.href,
          });
        } else if (!!data && !!data.results && !!data.results.length) {
          fetch(
            `/markread?visitorId=${visitorId}&subId=${data.results
              .map(row => row.sub_id)
              .join(',')}`,
          ).then(async response => {
            setMarkings(await response.json());
            setOverLimit(false);
            setKeyDisabled(false);
          });
        } else {
          setMarkings([]);
          setOverLimit(false);
          setKeyDisabled(false);
        }
      });
    },
    [city, loading, name, order, priorIndex, sort, state, visitorId, year, zip],
  );

  useEffect(() => {
    if (!searchParamsProcessed) {
      if (!!searchParams.get('email')) setEmail(searchParams.get('email'));
      if (!!searchParams.get('phone')) setPhone(searchParams.get('phone'));
      if (!!searchParams.get('state')) setState(searchParams.get('state'));
      if (!!searchParams.get('year'))
        setYear(parseInt(searchParams.get('year'), 10));
      if (!!searchParams.get('zip')) setZip(searchParams.get('zip'));

      if (!!searchParams.get('name')) {
        setName(searchParams.get('name'));
        setInitialSearchStage(1);
      }

      setSearchParamsProcessed(true);
    }
  }, [search, searchParams, searchParamsProcessed]);

  useEffect(() => {
    if (initialSearchStage === 1 && !!name) {
      search();
      setInitialSearchStage(2);
    }
  }, [initialSearchStage, name, search]);

  useEffect(() => {
    FingerprintJS.load().then(async fingerprint => {
      const { visitorId: newVisitorId } = await fingerprint.get();
      setVisitorId(newVisitorId);
    });
  }, []);

  useEffect(() => {
    if (window.location.href.startsWith('https://fec-audit.pages.dev'))
      window.location.href = '';
  }, []);

  return (
    <div>
      {SubmitModal}

      <ShareModal
        show={showShareModal}
        onHide={() => setShowShareModal(false)}
      />

      <Row>
        <Col xs={12} className="fs-4 mb-5 text-light">
          <p className="fs-3 fw-bold">
            Find out if you are a victim of political donor identity theft.
          </p>
          <p>
            Law enforcement is investigating a new form of identity theft called
            “straw donors” or "smurfing," in which fraudsters use the identities
            of innocent victims to make political contributions to candidates.
          </p>
          <p>
            CheckMyDonation.org is a free public service to identify potential
            victims.
          </p>
        </Col>
      </Row>

      <Row>
        <Col xs={12} className="fs-4 mb-5 text-light">
          <p className="fs-3 fw-bold">How To Use.</p>
          <p>
            Search public donation records below. CheckMyDonation.org will show
            you all political donations reported to the government from donors
            with your name. Then flag any donation that you don't recognize as{' '}
            <span className="text-danger">
              <X />
            </span>{' '}
            “suspicious”.
          </p>
          <p>
            If you are a potential victim, then you have the option to share
            your contact information with law enforcement for follow-up.
          </p>
        </Col>
      </Row>

      <Row>
        <Col xs={12}>
          <div className="fs-4 mb-3 text-light text-start">
            1. Search by your name.
          </div>
          <Searchbar
            loading={loading}
            className="mb-5"
            city={city}
            setCity={setCity}
            name={name}
            search={search}
            setName={setName}
            setPriorIndex={setPriorIndex}
            setState={setState}
            setYear={setYear}
            setZip={setZip}
            state={state}
            year={year}
            zip={zip}
          />

          <div className="fs-4 mb-3 mt-5 text-light text-start">
            <p>
              2. The following political donations have been reported in your
              name to the Federal Election Commission (FEC).{' '}
            </p>

            <p>
              Important note: Please check the address, occupation, and full
              name to make sure you are the reported donor (and not someone else
              with the same or similar name).
            </p>

            <p className="fs-3 fw-bold">
              Flag as suspicious any donation you do not recognize.
            </p>
          </div>

          {!!loading && (
            <div className="fs-4 text-white">
              Retrieving data from FEC. Results may take a few seconds.
              <Placeholder as="p" animation="glow" className="mt-3">
                <Placeholder xs={12} bg="light" className="mt-3 p-4" />
                <Placeholder xs={12} bg="light" className="mt-3 p-4" />
                <Placeholder xs={12} bg="light" className="mt-3 p-4" />
                <Placeholder xs={12} bg="light" className="mt-3 p-4" />
                <Placeholder xs={12} bg="light" className="mt-3 p-4" />
              </Placeholder>
            </div>
          )}

          {!loading && (
            <>
              {!keyDisabled && !overLimit && (
                <Pagination
                  className="mb-3"
                  data={data}
                  loading={loading}
                  priorIndex={priorIndex}
                  search={search}
                  setPriorIndex={setPriorIndex}
                />
              )}

              {!!overLimit && (
                <Alert variant="danger">
                  We are experiencing a high volume. Our attempts to retrieve
                  FEC data is currently being rate-limited. We hope to resolve
                  this soon. Please try again in a few moments.
                </Alert>
              )}

              {!!keyDisabled && (
                <Alert variant="danger">
                  The FEC is currently prohibiting us from accessing public
                  records. Please try again in a few moments.
                </Alert>
              )}

              {!keyDisabled && !overLimit && (
                <Results
                  data={data}
                  draft={draft}
                  loading={loading}
                  marking={marking}
                  onMark={onMark}
                />
              )}

              {!keyDisabled && !overLimit && (
                <Pagination
                  className="mt-3"
                  data={data}
                  loading={loading}
                  priorIndex={priorIndex}
                  search={search}
                />
              )}

              {!!data && !!data.results && !!data.results.length && (
                <Row className="my-3">
                  <Col md={10} />
                  <Col md={2}>
                    <Button
                      disabled={!draft || !draft.length}
                      size="lg"
                      className="w-100"
                      onClick={handleSubmitClick}
                    >
                      Submit
                    </Button>
                  </Col>
                </Row>
              )}
            </>
          )}
        </Col>
      </Row>
    </div>
  );
}

export default Home;
