import React from 'react';
import { Button, ButtonGroup } from 'react-bootstrap';
import { CaretLeftFill, CaretRightFill } from 'react-bootstrap-icons';

const Pagination = ({ className, data, loading, priorIndex, search }) => {
  const pageStart = (priorIndex ?? []).length;
  const pageEnd = pageStart + 1;
  const offsetStart = pageStart * data?.pagination?.per_page + 1;
  const offsetEnd = Math.min(
    pageEnd * (data?.pagination?.per_page + 1) - 1,
    data?.pagination?.count,
  );

  const nextPage = () => {
    search(null, null, [
      ...new Set([...(priorIndex ?? []), data.pagination.last_indexes]),
    ]);
  };

  const previousPage = () => {
    priorIndex.pop();
    search(null, null, priorIndex);
  };

  if (!data || !data?.pagination || !data.pagination.count) return null;
  return (
    <div className={`text-end text-light w-100 ${className}`}>
      Showing {offsetStart.toLocaleString()} to {offsetEnd.toLocaleString()} of{' '}
      {data.pagination.count.toLocaleString()} entries
      <ButtonGroup className="ms-2">
        {offsetStart > 1 && (
          <Button
            disabled={loading}
            variant="outline-light"
            className="p-1"
            onClick={previousPage}
          >
            <CaretLeftFill />
          </Button>
        )}
        {offsetEnd < data.pagination.count && (
          <Button
            disabled={loading}
            variant="outline-light"
            className="p-1"
            onClick={nextPage}
          >
            <CaretRightFill />
          </Button>
        )}
      </ButtonGroup>
    </div>
  );
};

export default Pagination;
