import React from 'react';
import content from './CheckMyDonationUserAgreementTermsConditions';

const TermsAndConditions = () => {
  return (
    <div className="legalese">
      <div dangerouslySetInnerHTML={{ __html: content }} />
    </div>
  );
};

export default TermsAndConditions;
