import React, { useCallback } from 'react';
import { Alert, Container, Row } from 'react-bootstrap';
import Record from '../Record';

const Results = ({ data, draft, marking, onMark = () => {} }) => {
  const getEntryClass = useCallback(
    (row, index) => {
      const classDefault = index % 2 ? 'bg-dark' : 'bg-dark2';

      try {
        const markingAndDraft = [...(marking ?? []), ...(draft ?? [])];
        const markingAndDraftSorted = (markingAndDraft ?? []).sort(
          (a, b) => (b.createdAt ?? 0) - (a.createdAt ?? 0),
        );
        const rowMarking = (markingAndDraftSorted ?? []).filter(
          mark => mark.subId === row.sub_id,
        )[0];
        if (!rowMarking) return classDefault;
        else if (!!rowMarking.suspicious) return 'suspicious';
        else return classDefault;
      } catch (e) {
        return classDefault;
      }
    },
    [draft, marking],
  );

  const callMark = (valid, row) => {
    console.log('debug', row);

    onMark({
      amount: row.contribution_receipt_amount,
      createdAt: Date.now(),
      imageNumber: row.image_number,
      state: row.contributor_state,
      subId: row.sub_id,
      suspicious: true,
      zip: row.contributor_zip,
    });
  };

  return (
    <Container fluid className="p-0">
      <Row>
        {!!data && !data?.results?.length && (
          <Alert variant="light" className="mt-3 w-100">
            The FEC does not report any donations in this name.
          </Alert>
        )}

        {!!data &&
          !!data?.results?.length &&
          data.results.map((row, index) => (
            <Record
              callMark={callMark}
              data={row}
              getEntryClass={getEntryClass}
              index={index}
              isLast={index === data.results.length - 1}
            />
          ))}
      </Row>
    </Container>
  );
};

export default Results;
