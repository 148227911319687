import React from 'react';
import content from './PrivacyPolicyforCheckMyDonation.org';

const PrivacyPolicy = () => {
  return (
    <div className="legalese">
      <div dangerouslySetInnerHTML={{ __html: content }} />
    </div>
  );
};

export default PrivacyPolicy;
